*,
::after,
::before {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

svg {
  vertical-align: middle;
}

b {
  font-weight: 500;
}

ul {
  list-style: none;
}

ul li {
  position: relative;
}

h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

h2 {
  font-size: 1.25rem;
  font-weight: 500;
}

h3 {
  font-size: 1.125rem;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
}

h5 {
  font-size: 0.8125rem;
  font-weight: 500;
}

h6 {
  font: 0.06875rem;
  font-weight: 500;
}

body,
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Sailec', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  color: #262626;
}

#root {
  height: 100%;
}

body > iframe {
  pointer-events: none;
}
